/**
 * Image - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography } from 'theme';

interface Props {
	widthAuto: boolean;
}
export const Img = styled.img<Props>`
	display: block;
	width: ${(props) => (props.widthAuto ? 'auto' : '100%')};
`;

export const Figure = styled.figure`
	margin: 0 0 1rem;

	${Img} {
		margin-bottom: 0;
	}
`;

export const FigCaption = styled.figcaption`
	padding: 0.5rem 0.75rem;
	background-color: ${colors.blueLighter};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	color: ${colors.black};
	width: 100%;
`;

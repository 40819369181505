/**
 * CalendarListBlock
 */

import React from 'react';

import {
	ButtonContainer,
	CalendarItem,
	CalendarItemDate,
	CalendarItemDetails,
	CalendarItemHeading,
	CalendarItemInfo,
	CalendarItemLink,
	CalendarItemLocation,
	CalendarItemTime,
	CalendarMore,
	CalendarMoreIconBadge,
	Container,
	Heading,
	ListItem,
	OrderedList,
	VerticalBarSpacer,
} from './CalendarListBlock.styles';

import Icon from 'components/Icon';
import Button from 'components/Button';
import { colors } from 'theme';
import { CalenderListItem } from 'types/listings';
import { CalendarListBlockModel } from 'types/blocks';

/** Main description for this component. */
const CalendarListBlock: React.FC<CalendarListBlockModel> = ({
	heading,
	items,
	button,
	translations,
}) => {
	return (
		<Container>
			<Heading>{heading}</Heading>
			<OrderedList>
				{items.map((item: CalenderListItem, i: number) => (
					<ListItem key={`${item.id}-${i}`}>
						<CalendarItem>
							<CalendarItemLink href={item.link}>
								<CalendarItemInfo>
									<CalendarItemHeading>{item.heading}</CalendarItemHeading>
									<CalendarItemDetails>
										<CalendarItemDate>{item.date}</CalendarItemDate>
										<VerticalBarSpacer aria-hidden={true} />
										<CalendarItemTime>{item.time}</CalendarItemTime>
										<VerticalBarSpacer aria-hidden={true} />
										<CalendarItemLocation>{item.location}</CalendarItemLocation>
									</CalendarItemDetails>
								</CalendarItemInfo>
								<CalendarMore>
									<span>{translations.readMoreLabel}</span>
									<CalendarMoreIconBadge>
										<Icon
											color={colors.linkColorDark}
											icon={'arrowRight'}
											size={0}
											aria-hidden="true"
										/>
									</CalendarMoreIconBadge>
								</CalendarMore>
							</CalendarItemLink>
						</CalendarItem>
					</ListItem>
				))}
			</OrderedList>
			{button?.link && button?.text && (
				<ButtonContainer>
					<Button type="link" variant="outlined" href={button.link}>
						{button.text}
					</Button>
				</ButtonContainer>
			)}
		</Container>
	);
};

export default CalendarListBlock;

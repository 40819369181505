/**
 * LinkListBlock
 */

import BlockContainer from 'layouts/BlockLayout';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { LinkType } from 'types/global';
import Icon from 'components/Icon';
import {
	Container,
	StyledCell,
	Heading,
	Preamble,
	LinkList,
	LinkListItem,
	StyledLink,
	ReadMoreLabel,
	LeftRow,
	RightRow,
	IconBadge,
	ButtonContainer,
} from './LinkListBlock.styles';
import Button from 'components/Button';
import { ScreenReaderText } from 'components/Text/Text.styles';
import { GridProps } from 'components/Grid';
import { LinkListBlockItem } from 'types/blocks';

interface Props {
	/** Screenreader text */
	screenReaderText: string;

	/** Columns */
	columns?: LinkListBlockItem[];
}

/** LinkListBlock - A list of links. */
const LinkListBlock: React.FC<Props> = ({ columns, screenReaderText }) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.mediumDesktop,
	});

	const calculateGap = (): string => {
		if (
			(isPhone || isTablet) &&
			columns?.length &&
			columns?.length > 1 &&
			!columns[1].heading
		)
			return GridProps.noGap;
		if (isPhone || isTablet) return GridProps.mediumGap;

		return GridProps.largeGap;
	};

	const calculatePadding = (index: number): string => {
		if (!columns || !columns[0] || columns[1]?.heading || index == 0) return '';
		if (columns[0].heading && columns[0].preamble) return 'addLargeTopPadding';
		if (columns[0].heading && !columns[0].preamble)
			return 'addMediumTopPadding';

		return '';
	};

	return (
		<BlockContainer>
			<Container inner manualGutter={calculateGap()}>
				{columns?.map((column: LinkListBlockItem, index: number) => {
					const { heading, preamble, links, button } = column;

					return (
						<StyledCell
							key={index}
							desktop={columns.length > 1 ? 6 : 12}
							tablet={12}
							phone={12}
							className={calculatePadding(index)}
						>
							<div>
								{heading && <Heading>{heading}</Heading>}

								{preamble && <Preamble>{preamble}</Preamble>}
							</div>

							<div>
								{links && (
									<LinkList>
										{links.map((link: LinkType, i: number) => (
											<LinkListItem key={i}>
												<StyledLink href={link.link}>
													<LeftRow>
														<ScreenReaderText>
															{screenReaderText}
														</ScreenReaderText>
														<ReadMoreLabel>{link.text}</ReadMoreLabel>
													</LeftRow>

													<RightRow>
														<IconBadge>
															<Icon
																icon={'arrowRight'}
																size={0}
																aria-hidden="true"
															/>
														</IconBadge>
													</RightRow>
												</StyledLink>
											</LinkListItem>
										))}
									</LinkList>
								)}

								{button && (
									<ButtonContainer>
										<Button type="link" variant="outlined" href={button.link}>
											{button.text}
										</Button>
									</ButtonContainer>
								)}
							</div>
						</StyledCell>
					);
				})}
			</Container>
		</BlockContainer>
	);
};

export default LinkListBlock;

/**
 * CalendarListBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { mediaQueries, colors, typography } from 'theme';
import Link from 'components/Link';

export interface ThemeProps {
	backgroundColor?: 'lightBlue' | 'blue' | 'white';
	headingAlignment?: 'left' | 'center';
	isPhone?: boolean;
}

export const ButtonWrapper = styled.div`
	text-align: center;
	width: 100%;
`;

export const CalendarItem = styled.article`
	display: flex;
	width: 100%;

	&:hover {
		h3 {
			text-decoration: underline;
		}

		div:last-child span {
			text-decoration: underline;
		}
	}
`;

export const CalendarItemLink = styled(Link)`
	display: flex;
	width: 100%;

	text-decoration: none;
	color: ${colors.black};
`;

export const CalendarItemDate = styled.span`
	display: block;
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.semiBold};
`;

export const CalendarItemDetails = styled.p`
	display: flex;
	margin-bottom: 1rem;

	${mediaQueries.phoneOrTablet} {
		display: block;
	}
`;

export const CalendarItemHeading = styled.h3`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(20)};
	font-weight: ${typography.fontWeight.semiBold};
	margin: 1rem 0 0.5rem;
	padding: 0;
	word-break: break-word;
`;

export const CalendarItemInfo = styled.div`
	width: 100%;
`;

export const CalendarItemLocation = styled.span`
	color: ${colors.grey};
	display: block;
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.bold};
	margin: 0;
	padding: 0;
`;

export const CalendarItemTime = styled.time`
	color: ${colors.grey};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.semiBold};
`;

export const CalendarMore = styled.div`
	align-items: center;
	color: ${colors.linkColorDark};
	display: flex;
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.semiBold};
	margin: auto;
	width: auto;
	white-space: nowrap;
`;

export const CalendarMoreIconBadge = styled.div`
	align-items: center;
	background-color: ${colors.greyLightest};
	border-radius: 1.5rem;
	display: flex;
	height: 3rem;
	justify-content: center;
	margin-left: 0.75rem;
	width: 3rem;
`;

export const Container = styled.div`
	margin: 0 auto;
	max-width: 120rem;
	padding: 3rem 7.5rem;
	width: 100%;
	${mediaQueries.phoneOrTablet} {
		padding: 3rem 4.5rem;
	}
	${mediaQueries.phone} {
		padding: 3rem 0;
	}
`;

export const Heading = styled.h2`
	color: ${colors.greyDark};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(25)};
	font-weight: ${typography.fontWeight.bold};
	line-height: ${px2rem(32)};
	margin: 0 0 ${px2rem(32)} 0;
	text-align: center;
	width: 100%;
	word-break: break-word;
`;

export const ListItem = styled.li`
	border-top: 1px solid ${colors.greyLighter};
	${mediaQueries.phoneOrTablet} {
		margin: 0 1rem;
	}
`;

export const OrderedList = styled.ol`
	border-bottom: 1px solid ${colors.greyLighter};
	list-style: none;
	margin: 0 0 3rem;
	padding: 0;
`;

export const VerticalBarSpacer = styled.span`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.normal};
	margin: 0;
	padding: 0 0.75rem;

	&:before {
		color: ${colors.grey};
		content: '|';
	}
	${mediaQueries.phoneOrTablet} {
		display: none;
	}
`;

export const ButtonContainer = styled.div<ThemeProps>`
	max-width: ${px2rem(262)};
	margin: ${px2rem(52)} auto ${px2rem(28)};
	text-align: center;

	${({ backgroundColor }) => {
		if (backgroundColor === 'lightBlue' || backgroundColor === 'white') {
			return `
				a {
					background-color: transparent;
				}
            `;
		}
	}}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: ${px2rem(32)} auto;
	}
`;

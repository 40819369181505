/**
 * TextBlock
 */

import React from 'react';
import {
	Container,
	TextContainer,
	Heading,
	StyledPreamble,
	OuterContainer,
	WrapperContainer,
} from './TextBlock.styles';
import EpiFragments from 'components/EpiFragments';
import { LinkType } from '../../types/global';
import Text from '../Text';
import Link from '../Link';
import styled from 'styled-components';
import { colors } from 'theme';

const TextBlockLink = styled(Link)`
	color: ${colors.blue};
	display: block;
	margin-block: 1.25rem;
	&:hover {
		text-decoration: none;
	}
`;

interface Props {
	heading?: string;
	preamble?: string;
	text?: any;
	backgroundColor?: 'lightBlue' | 'white' | 'transparent';
	headingAlignment?: 'left' | 'center';
	blockAlignment?: 'left' | 'center';
	isPageHeader?: boolean;
	links?: LinkType[];
	headingForLinks?: string;
}

/** Textblock */
const TextBlock: React.FC<Props> = ({
	heading,
	preamble,
	text,
	links,
	headingForLinks,
	backgroundColor = 'lightBlue',
	headingAlignment = 'left',
	blockAlignment = 'center',
	isPageHeader,
}) => {
	return (
		<WrapperContainer backgroundColor={backgroundColor}>
			<OuterContainer backgroundColor={backgroundColor}>
				<Container
					backgroundColor={backgroundColor}
					blockAlignment={blockAlignment}
				>
					<TextContainer>
						{heading && (
							<Heading
								as={isPageHeader ? 'h1' : 'h2'}
								headingAlignment={headingAlignment}
							>
								{heading}
							</Heading>
						)}

						{preamble && <StyledPreamble>{preamble}</StyledPreamble>}

						<EpiFragments fragments={text?.fragments} />

						{headingForLinks && links && (
							<div>
								<Text as={'h3'}>{headingForLinks}</Text>
								{links.map((link, index) => (
									<TextBlockLink key={index} href={link.link}>
										{link.text}
									</TextBlockLink>
								))}
							</div>
						)}
					</TextContainer>
				</Container>
			</OuterContainer>
		</WrapperContainer>
	);
};

export default TextBlock;
